$color-primary: #3f51b5;
$color-accent: #fbc02d;
$color-green: #4CAF50;
$color-red: #D32F2F;
$color-yellow: #fbc02d;
$color-yellow-bright: #ffea00;
$color-purple: #9c27b0;
$color-orange: #ff9800;
$color-blue: #3f51b5;
$color-pink: #e91e63;
$color-teal: #37c8c7;
$color-background-gray-1: rgba(0, 0, 0, 0.01);
$color-background-gray-5: rgba(0, 0, 0, 0.05);
$color-background-gray-10: rgba(0, 0, 0, 0.1);
$color-hover-background: #f5f5f5;
$color-hover-background-darker: #ebebeb;
$color-border-gray: #e0e0e0;
$color-loading-background: rgba(255, 255, 255, 0.8);

/*$text-color-inactive: rgba(0, 0, 0, 0.19);
$text-color-inactive-darker: rgba(0, 0, 0, 0.3);*/
$text-color-inactive: #cccccc;
$text-color-inactive-darker: #bbbbbb;
$text-color-primary: #212529;

$breakpoint-mobile: 600px;

@function rem($pixels, $context: 16) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return calc($pixels / $context) * 1rem;
}
