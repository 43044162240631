@use '@angular/material' as mat;
@import '/node_modules/bootstrap/scss/bootstrap-reboot';
@import '/node_modules/bootstrap/scss/bootstrap-grid';
@import '/src/assets/scss/vars';

@include mat.core();

$mat-theme-typography: mat.define-typography-config(
    $font-family: 'Roboto',
    $headline-1:
        mat.define-typography-level(
            $font-weight: 300,
            $line-height: rem(112),
            $font-size: rem(112),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
    $headline-2:
        mat.define-typography-level(
            $font-weight: 400,
            $line-height: rem(56),
            $font-size: rem(56),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
    $headline-3:
        mat.define-typography-level(
            $font-weight: 400,
            $line-height: rem(48),
            $font-size: rem(45),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
    $headline-4:
        mat.define-typography-level(
            $font-weight: 400,
            $line-height: rem(40),
            $font-size: rem(34),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
    $headline-5:
        mat.define-typography-level(
            $font-weight: 400,
            $line-height: rem(32),
            $font-size: rem(24),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
    $headline-6:
        mat.define-typography-level(
            $font-weight: 500,
            $line-height: rem(32),
            $font-size: rem(20),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
    $subtitle-1:
        mat.define-typography-level(
            $font-weight: 400,
            $line-height: rem(28),
            $font-size: rem(16),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
    $subtitle-2:
        mat.define-typography-level(
            $font-weight: 500,
            $line-height: rem(24),
            $font-size: rem(15),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
    $body-1:
        mat.define-typography-level(
            $font-weight: 400,
            $line-height: rem(24),
            $font-size: rem(16),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
    $body-2:
        mat.define-typography-level(
            $font-weight: 400,
            $line-height: rem(20),
            $font-size: rem(16),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
    $caption:
        mat.define-typography-level(
            $font-weight: 400,
            $line-height: rem(20),
            $font-size: rem(14),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
    $button:
        mat.define-typography-level(
            $font-weight: 500,
            $line-height: rem(16),
            $font-size: rem(16),
            $letter-spacing: normal,
            $font-family: 'Roboto',
        ),
);

$mat-custom-yellow: (
    50: #fff7e6,
    100: #feecc0,
    200: #fde096,
    300: #fcd36c,
    400: #fcc94d,
    500: #fbc02d,
    600: #faba28,
    700: #fab222,
    800: #f9aa1c,
    900: #f89c11,
    A100: #ffffff,
    A200: #fff9f1,
    A400: #ffe3be,
    A700: #ffd8a5,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$theme-primary: mat.define-palette(mat.$indigo-palette);
$theme-accent: mat.define-palette($mat-custom-yellow);
$theme-warn: mat.define-palette(mat.$red-palette);

$mat-theme: mat.define-light-theme(
    (
        color: (
            primary: $theme-primary,
            accent: $theme-accent,
            warn: $theme-warn,
        ),
        typography: $mat-theme-typography,
    )
);

@include mat.core-theme($mat-theme);
@include mat.all-component-themes($mat-theme);

html {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;

    ::-webkit-scrollbar {
        /*width: rem(12);
        height: rem(5);*/
    }

    ::-webkit-scrollbar-thumb {
        background: rgb(170, 170, 170);
        border: rem(3) solid rgb(213, 213, 213);
        border-radius: calc(rem(16) / 2);
        cursor: pointer;
    }

    ::-webkit-scrollbar-track {
        background: rgb(213, 213, 213);
    }

    ::-webkit-scrollbar-corner {
        background: rgb(213, 213, 213);
    }

    body {
        background-color: #fafafa;
        font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        min-height: 100%;
        color: #212529;

        h1,
        h2,
        h3 {
            font-weight: 500;

            &.mat-headline-3 {
                @media only screen and (max-width: $breakpoint-mobile) {
                    font-size: rem(28);
                    line-height: rem(28);
                }
            }
        }

        h1 {
            line-height: rem(48);
            font-size: rem(45);
        }

        strong {
            font-weight: 500;
        }

        button:focus {
            outline: none;
        }

        label {
            margin: 0;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-wrapper {
            .mat-icon {
                position: relative;
                top: rem(-1);
            }
        }

        .container {
            @media (min-width: 1200px) {
                max-width: rem(1600);
            }
        }

        .mat-mdc-card {
            &.compact {
                padding: 0;

                .mat-mdc-card-title {
                    padding: rem(20);
                    font-size: rem(16);
                    font-weight: 500;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    margin: 0;
                    line-height: rem(16);

                    .mat-icon {
                        vertical-align: middle;
                        position: relative;
                        top: rem(-2);
                    }
                }
            }
        }

        .mat-mdc-tooltip {
            font-size: rem(14);
        }

        .mat-mdc-tab-group {
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
            &.mat-tab-label-fill {
                /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
                .mat-tab-label {
                    flex-grow: 1;
                }
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        .mat-tab-body-wrapper {
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
            .mat-tab-body-content {
                overflow: hidden;
            }
        }

        @keyframes placeHolderShimmer {
            0% {
                background-position: rem(-468) 0;
            }
            100% {
                background-position: rem(468) 0;
            }
        }

        .ph {
            background-color: #ced4da;
            height: rem(25);
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeHolderShimmer;
            animation-timing-function: linear;
            background: #f6f7f8;
            background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
            background-size: rem(1000) rem(104);
            position: relative;
            overflow: hidden;
            width: 100%;
        }

        .mat-mdc-dialog-container {
            position: relative;

            .mat-mdc-dialog-actions {
                padding: rem(15) rem(24) rem(24) rem(24);
            }
        }

        .messages-panel-backdrop {
            background-color: rgba(0, 0, 0, 0.8);
        }

        .messages-panel {
            height: 100%;
        }

        .messages-panel {
            .mat-mdc-form-field.mat-form-field-appearance-outline {
                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
                .mat-form-field-wrapper {
                    padding-bottom: rem(10);
                }
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-outline {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-outline-start,
            .mat-form-field-outline-end {
                border-radius: rem(2);
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
        .mat-select-compact {
            .mat-mdc-option {
                font-size: rem(14);
                height: rem(30);
            }
        }

        .toggle-messages {
            .mat-badge-content {
                top: rem(-5) !important;
                right: rem(-5) !important;
            }
        }

        .mat-mdc-menu-panel {
            .mat-mdc-menu-content {
                .title {
                    font-weight: 500;
                    padding: rem(10) rem(16);
                    margin-bottom: rem(8);
                    display: block;
                    border-bottom: rem(1) solid $color-border-gray;
                }
                .spacer {
                    display: block;
                    padding: rem(7) 0;
                    content: ' ';
                }
            }

            &.wide {
                max-width: rem(600);
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .mat-checkbox-inner-container {
            width: rem(20);
            height: rem(20);
        }

        .mat-mdc-option {
            .mat-pseudo-checkbox-checked {
                background: $color-accent !important;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
            &.mat-selected:not(.mat-option-disabled) {
                color: $color-accent !important;
            }
        }

        .badge {
            margin: 0 rem(5);
            padding: rem(3) rem(7);
            border-radius: rem(5);
            text-align: center;
            background-color: $color-accent;
            font-size: rem(14);
            color: #ffffff;
        }

        .mat-mdc-tooltip {
            white-space: pre-line !important;
        }

        app-messages {
            position: relative;
            z-index: 99;
        }

        textarea {
            line-height: 1.4 !important;
        }

        .calendar-day-highlight-green,
        .calendar-day-highlight-red,
        .calendar-day-highlight-yellow {
            .mat-calendar-body-cell-content {
                border-radius: 100%;
                color: #ffffff;
                &:hover {
                    &:not(.mat-calendar-body-selected) {
                        color: $text-color-primary;
                    }
                }
            }
        }

        .calendar-day-highlight-green {
            .mat-calendar-body-cell-content {
                &:not(.mat-calendar-body-selected) {
                    background: $color-green;
                }
            }
        }

        .calendar-day-highlight-red {
            .mat-calendar-body-cell-content {
                &:not(.mat-calendar-body-selected) {
                    background: $color-red;
                }
            }
        }

        .calendar-day-highlight-yellow {
            .mat-calendar-body-cell-content {
                &:not(.mat-calendar-body-selected) {
                    background: $color-yellow;
                }
            }
        }

        .driving-log-main-mobile-menu {
            margin-right: rem(15);
        }

        .mat-mdc-menu-item {
            .mat-icon {
                margin-right: rem(10);
                position: relative;
                top: rem(-2);
            }
        }

        .alert {
            display: flex;
            flex-direction: row;
            border-radius: rem(2);
            background: rgba(0, 0, 0, 0.1);

            &.alert-warning {
                background: $color-yellow;
                color: #ffffff;
            }

            .alert-message {
                flex-grow: 1;
                display: flex;
                align-items: center;
                padding: rem(10) rem(15) rem(10) rem(10);
            }

            .alert-icon {
                display: flex;
                flex-grow: 0;
                align-items: center;
                padding: rem(10) rem(15);
                background: rgba(0, 0, 0, 0.1);

                .mat-icon {
                    font-size: rem(24);
                }
            }
        }

        .cdk-overlay-backdrop {
            &.white {
                background-color: rgb(255, 255, 255);
            }

            &.black {
                background-color: rgb(0, 0, 0);
            }
        }

        .mdc-data-table__cell,
        .mdc-data-table__header-cell {
            padding: 0;

            &:first-child {
                padding-left: rem(16);
            }

            &:last-child {
                padding-right: rem(16);
            }
        }

        .mat-mdc-card-content {
            padding: 0;

            &:last-child {
                padding: 0;
            }
        }

        .mat-mdc-dialog-container {
            .mdc-dialog__content {
                color: #212529;
                line-height: 1.5;
            }
        }

        .date-range {
            display: flex;
            align-items: center;

            span {
                display: block;
                height: rem(42);
                margin: 0 rem(10);
            }

            .mat-mdc-form-field {
                display: inline-block;
                width: rem(160);

                input {
                    cursor: pointer;
                }
            }
        }

        .mdc-data-table__row:last-child .mdc-data-table__cell {
            border-bottom: rem(1) solid rgba(0, 0, 0, 0.12);
        }

        .mat-mdc-form-field {
            &.no-subscript {
                .mat-mdc-form-field-subscript-wrapper {
                    display: none;
                }
            }
        }

        .mat-mdc-optgroup {
            .mat-mdc-optgroup-label {
                .mdc-list-item__primary-text {
                    font-weight: 500;
                }
            }
        }

        .mat-datepicker-popup {
            button.highlighted-date-picker-date {
                .mat-calendar-body-cell-content {
                    background: rgba($color-accent, 0.6);

                    &.mat-calendar-body-selected {
                        background: $color-primary;
                    }
                }
            }
        }
    }
}
